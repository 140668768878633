



























































import { Mode } from "@/models/enums/global.enum";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

const { mapMutations, mapState } = createNamespacedHelpers("productStoreV2");

interface TableRow {
  key: string;
  code: string;
  id: string;
}

export default Vue.extend({
  name: "ProductItemCodes",
  data() {
    return {
      dataSource: [] as TableRow[],
      selectedRowKeys: [] as string[],
      vmItemCode: ""
    };
  },
  computed: {
    ...mapState({
      storeProduct: (state: any) => state.theProduct
    })
  },
  mounted() {
    if (this.$route.meta.mode === Mode.EDIT) {
      this.fillTable();
    }
  },
  methods: {
    ...mapMutations([
      "SET_PRODUCT"
    ]),
    addRow(val: string): void {
      let datasource: TableRow[] = [...this.dataSource];
      const row: TableRow = {
        key: Date.now().toString(),
        code: val,
        id: ""
      };
      datasource.push(row);
      this.dataSource = datasource;
      this.mutationProduct(datasource);
      this.vmItemCode = "";
    },
    onRowSelect(rowKeys: string[]): void {
      this.selectedRowKeys = rowKeys;
    },
    deleteRow(): void {
      const { dataSource } = this;
      const ids = dataSource.filter(data => this.selectedRowKeys.includes(data.key));
      this.dataSource = dataSource.filter(data => !this.selectedRowKeys.includes(data.key));
      this.selectedRowKeys = [];
      this.mutationProduct(this.dataSource);
      this.SET_PRODUCT({
        ...this.storeProduct,
        ...{
          deletedItemCodeIds: ids.map(x => x.id)
        }
      });
    },
    fillTable(): void {
      this.dataSource = [...this.storeProduct.itemCodes].map((z, i) => { return { code: z.code, id: z.id, key: i.toString() };});
    },
    oninputCode(): void {
      this.mutationProduct(this.dataSource);
    },
    mutationProduct(dt: TableRow[]): void {
      this.SET_PRODUCT({...this.storeProduct, ...{ itemCodes: [...dt].map(r => { return { id: r.id, code: r.code };}) }});
    }
  },
});
