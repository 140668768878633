var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c(
                "a-input-search",
                {
                  attrs: { placeholder: _vm.$t("lbl_type_here") },
                  on: { search: _vm.addRow },
                  model: {
                    value: _vm.vmItemCode,
                    callback: function($$v) {
                      _vm.vmItemCode = $$v
                    },
                    expression: "vmItemCode"
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        slot: "enterButton",
                        type: "primary",
                        icon: "plus"
                      },
                      slot: "enterButton"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.deleteRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dataSource,
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onRowSelect
                    }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "code",
                      attrs: { "data-index": "code" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("a-input", {
                                on: { input: _vm.oninputCode },
                                model: {
                                  value: record.code,
                                  callback: function($$v) {
                                    _vm.$set(record, "code", $$v)
                                  },
                                  expression: "record.code"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_item_codes")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }